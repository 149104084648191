import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import ProductCard from "./ProductCard"
import ScrollToTop from "./ScrollToTop"
import Prods from "./Prods"
import Link from "@material-ui/core/Link"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: "80%",
		margin: "auto",
		marginBottom: 100,
	},
	grid: {
		height: 140,
		width: 100,
	},
	paper: {
		display: "flex",
		flexWrap: "wrap",
	},
	item: {
		width: "10%",
	},
	breadcrumbs: {
		marginBottom: 20,
	},
}))

export default function Products(props) {
	const [productCategory, setProductCategory] = useState(props.product)
	const spacing = 2
	const classes = useStyles()
	const products = Prods[props.product]

	return (
		<Grid container className={classes.root} spacing={2}>
			<Breadcrumbs
				separator="›"
				aria-label="breadcrumb"
				className={classes.breadcrumbs}>
				<Link color="inherit" href="#" onClick={() => props.back()}>
					Termékek
				</Link>
				{props.subcategory && (
					<Link
						color="inherit"
						href={"#"}
						onClick={() => props.subback()}>
						{props.subcategory}
					</Link>
				)}
				<Link color="inherit" href={"#"}>
					{props.translation}
				</Link>
			</Breadcrumbs>

			<Grid justify="center" container spacing={spacing}>
				{Object.entries(products).map((product, index) => (
					<Grid key={index} item>
						<ProductCard
							wrap
							productCategory={productCategory}
							images={product[1].img}
							title={product[1].title}
							shortDescription={product[1].desc}
							category={props.category}
						/>
					</Grid>
				))}
				<ScrollToTop />
			</Grid>
		</Grid>
	)
}
