import React from "react"

export default function Homepage() {
	const background =
		process.env.PUBLIC_URL + "/img/iron-steel-industry-metal.png"

	return (
		<div className="homepage">
			<div
				className="home-bg"
				style={{ backgroundImage: `url(${background})` }}>
				<div className="intro">
					<span className="name">Fertom-Kellék Kft.</span>
					<p>
						Több generáción keresztül családi vállalkozásban
						cipőipari szegeket gyártottunk. 1990-től, mint Fer-Ma
						Bt. kezdtük kibővíteni vállalkozásunkat, főképp
						cipőipari alapanyag nagykereskedelemmel.
					</p>
					<p>
						Több országból importálunk, hogy szélesebb választékkal
						tudjuk kiszolgálni vásárlóinkat. 2014-től, mint Fertom
						Kellék Kft. kizárólagos hazai forgalmazója vagyunk az
						angol Davis Odell, a spanyol GHL, az olasz Dunlop,
						Michelin cipőjavító anyagoknak, valamint az olasz
						Trepini gyár összes termékének, amik cipő, kárpitos, fa,
						és építőipari ragasztók.
					</p>
					<p>
						<strong>
							<u>
								Nagykereskedésünkben csak vállalkozókat tudunk
								kiszolgálni.
							</u>
						</strong>
					</p>
				</div>
			</div>
		</div>
	)
}
