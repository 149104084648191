const ProdObject = {
	michelin_termekek: {
		feltalp_michelin_city: {
			title: "Michelin City féltalp extra kopásálloságú",
			desc:
				"  vastagság 2mm  szín: fekete, barna  méret: 36-38, 39-41, 42-44, 45-47",
			img: ["feltalp_michelin_city.jpg"],
		},
		sarok_michelin_city: {
			title: "Michelin City sarok  extra kopásálloságú",
			desc:
				" vastagság 6mm  szín: fekete, barna  méret: 36-38, 39-41, 42-44, 45-47",
			img: ["sarok_michelin_city.jpg"],
		},
		feltalp_michelin_modern: {
			title: " Michelin Modern féltalp extra kopásálloságú ",
			desc: "  vastagság 2mm  szín: fekete, méret:  42-44, 45-47,48-50",
			img: ["feltalp_michelin_modern.jpg"],
		},
		sarok_michelin_modern: {
			title: " Michelin Modern  sarok  extra kopásálloságú ",
			desc: "vastagság 7mm  szín: fekete,   méret:  42-44, 45-47,48-50",
			img: ["sarok_michelin_modern.jpg"],
		},
		feltalp_michelin_outdoor: {
			title: " Michelin Outdoor féltalp extra kopásálloságú ",
			desc: "  vastagság 4mm  szín: fekete,  méret: 42-44, 45-47. 48-50",
			img: ["feltalp_michelin_outdoor.jpg"],
		},
		sarok_michelin_outdoor: {
			title: "Michelin Outdoor sarok  extra kopásálloságú ",
			desc: " vastagság 6mm  szín: fekete,   méret:  42-44, 45-47,48-50",
			img: ["sarok_michelin_outdoor.jpg"],
		},
		feltalp_michelin_winter: {
			title: "Michelin Winter féltalp extra kopásálloságú ",
			desc: "  vastagság 6mm  szín: fekete, méret:  42-44, 45-47,48-50",
			img: ["feltalp_michelin_winter.jpg"],
		},
		sarok_michelin_winter: {
			title: "Michelin Winter sarok  extra kopásálloságú ",
			desc: " vastagság 8mm  szín: fekete,   méret:  42-44, 45-47,48-50",
			img: ["sarok_michelin_winter.jpg"],
		},
		talp_michelin_trek: {
			title: "Michelin Trek talp extra kopásálloságú ",
			desc:
				"  vastagság talp 8mm, sarok 22mm  szín: fekete,   méret:  43-44, 45-46, 47-48",
			img: ["talp_michelin_trek.jpg"],
		},
		talp_michelin_defence: {
			title: "Michelin Defence talp extra kopásálloságú ",
			desc:
				"  vastagság ta 8mm  szín: fekete,   méret:  43-44, 45-46, 47-48",
			img: ["talp_michelin_defence.jpg"],
		},
		saroklemez_michelin_two: {
			title: "Michelin Two kétrétegű saroklemez",
			desc: "  méret: 600x880x6mm szín: fekete, barna, karamell ",
			img: [
				"saroklemez_michelin_two_caramel.jpg",
				"saroklemez_michelin_two_bezs.jpg",
				"saroklemez_michelin_two_barna.jpg",
				"saroklemez_michelin_two_fekete.jpg",
			],
		},
		saroklemez_michelin_one: {
			title: "Michelin One saroklemez",
			desc: "méret: 550x880x5mm, 550x880x6mm  szín: fekete, barna, bézs",
			img: ["saroklemez_michelin_one_fekete.jpg"],
		},
		csuszasgatlo_michelin_one: {
			title: "Michelin One csúszásgátló ",
			desc: "  méret: 550x880x2mm,   szín: fekete, barna, bézs",
			img: ["saroklemez_michelin_one_fekete.jpg"],
		},
		csuszasgatlo_michelin_slim: {
			title: "Michelin Slim csúszásgátló ",
			desc: "méret: 600x880x1mm,    szín: fekete, barna, bézs",
			img: [
				"csuszasgatlo_michelin_slim_bezs.jpg",
				"csuszasgatlo_michelin_slim_barna.jpg",
				"csuszasgatlo_michelin_slim_fekete.jpg",
			],
		},
		gumilemez_michelin_chusion: {
			title: "Michelin Chusion gumilemez",
			desc: "méret:  600x870x4mm, 600x870x6mm, 600x870x8mm",
			img: ["gumilemez_michelin_chusion.jpg"],
		},
	},
	ghl_termekek: {
		ghl_fek: {
			title: "GHL nagy kopásállóságú saroklemez",
			desc: " méret: 50x50 cm   vastagság: 2mm, 6mm ",
			img: ["ghl_saroklemez.jpg"],
		},

		luxe_csik: {
			title: "GHL kétrétegű nagy kopásállóságú javítócsík",
			desc: "",
			img: ["luxe_csik.jpg"],
		},
		luxe_dupl_jarofolt: {
			title: "Extra kopásállóságú kétrétegű csapos járófolt",
			desc: "minden darabot röntgen anyagvizsgálattal ellenőriznek",
			img: ["luxe_dupl_jarofolt.jpg"],
		},
		luxe_jarofolt: {
			title: "Extra kopásállóságú csapos járófolt",
			desc: "minden darabot röntgen anyagvizsgálattal ellenőriznek",
			img: ["luxe_jarofolt.jpg"],
		},
		csoszukito: {
			title: "Csőszűkítő",
			desc: "Csőszűkítő női cipő tűsarok javításhoz",
			img: ["csoszukito.jpg"],
		},
		muanyag_stift: {
			title: "Műanyag stift ",
			desc: "Műanyag stift női cipősarok javításhoz ",
			img: ["muanyag_stift.jpg"],
		},
	},
	dunlop_termekek: {
		feltalp_dunlop_winter: {
			title: "Dunlop Winter féltalp ",
			desc: "vastagság: 5mm			méret: 47/48, 49/50",
			img: ["feltalp_dunlop_winter.jpg"],
		},
		feltalp_dalpi: {
			title: "Dalpi féltalp",
			desc: "Műanyag stift női cipősarok javításhoz ",
			img: ["feltalp_dalpi.jpg"],
		},
		sarok_dunlop_winter: {
			title: "Dunlop Winter sarok",
			desc: "vastagság: 8mm méret: 43/44, 47/48, 49/50",
			img: ["sarok_dunlop_winter.jpg"],
		},
		sarok_dunlop_grip: {
			title: "Műanyag stiftDunlop Grip sarok	 ",
			desc: "  		vastagság: 8mm			méret: 70, 72, 74",
			img: ["sarok_dunlop_grip.jpg"],
		},
		sarok_dunlop_slick: {
			title: "Dunlop Slick sarok ",
			desc: "			vastagság: 7mm			méret: 68, 72, 76",
			img: ["sarok_dunlop_slick.jpg"],
		},
		varese_sarok: {
			title: "Varese saroklemez  ",
			desc: "saroklemez 700X650X6mm ",
			img: ["varese.jpg", "varesebar.jpg", "varesebezs.jpg"],
		},
		varese_csuszas: {
			title: "Varese csúszásgátló ",
			desc: "csúszásgátló 680x580x2mm",
			img: ["varese.jpg", "varesebar.jpg", "varesebezs.jpg"],
		},
	},
	gumilemezek: {
		gumilemez_eva: {
			title: "Eva gumilemez sima",
			desc:
				"                          8 és 18mm vastagságban 			méret: 1,15m3",
			img: ["gumilemez_eva.jpg"],
		},
		gumilemez_titan: {
			title: "Gumilemez titán /lengyel/",
			desc: "                                  méret: 3mm vastag",
			img: ["gumilemez_titan.jpg"],
		},
		gumilemez_transparent: {
			title: "Gumilemez transparent  /lengyel/ ",
			desc: "méret: 3,5mm vastag",
			img: [
				"gumilemez_transparent_fekete.jpg",
				"gumilemez_transparent_bezs.jpg",
			],
		},
		gumilemez_profilmintas: {
			title: "Gumilemez profilmintás titán /lengyel/",
			desc: "méret: 3,5mm vastag",
			img: ["titan_profilmintas.jpg"],
		},
		mikro_kavics: {
			title: "Gumilemez mikró kavics mintás /lengyel/",
			desc: "vastagság: 4, 6, 8, 10mm			méret: 0,6-0,7 m3",
			img: [
				"mikro_kavics_barna.jpg",
				"mikro_kavics_bezs.jpg",
				"mikro_kavics_feher.jpg",
				"mikro_kavics_feherx.jpg",
				"mikro_kavics_fekete.jpg",
				"mikro_kavics_feketex.jpg",
			],
		},
		mikro_cikk: {
			title: "Gumilemez mikró cikk-cakk mintás",
			desc:
				"                           vastagság: 4, 6, 8,mm			méret: 0,6-0,7 m3",
			img: ["mikro_cikk_fekete.jpg", "mikro_cikk_barna.jpg"],
		},
	},
	ragasztok: {
		uniflex_t120: {
			title: "Uniflex t120",
			desc:
				"Egykomponensű, alacsony viszkozitású, gyorsan száradó flexibilis ragasztó.  Alkalmazásában univerzális. Különösen ajánlott gumikhoz, műanyagokhoz, fémekhez, kerámiához, fához és kartonhoz.",
			img: ["uniflex_t120.jpg"],
		},
		uniflex_t300: {
			title: "Uniflex t300",
			desc:
				"Egykomponensű, gyorsan keményedő, fokozott viszkozitású flexibilis ragasztó, amely nem tapadó felületek ragasztására szolgál. Porózus anyagok, gumi,  fa, karton, papír, bőr, szövetek ragasztására tervezték. Kivételes rugalmasságának köszönhetően alkalmas rugalmas anyagok, például gumi, vagy rugalmas és porózus anyagok kombinációinak, például fa-gumi ragasztására.",
			img: ["uniflex_t300.jpg"],
		},
		tre_pini_preme_tubusos: {
			title: "Tre Pin Preme 75ml tubusos ragasztó",
			desc:
				"Közepes viszkozitású kontakt polikloroprén ragasztó. Nem tartalmaz toluolt és klórozott oldószereket. Különösen alkalmas bőr, parafa, kötél, fém, szövet, fa, poliuretán hab, stb. ragasztására.",
			img: ["tre_pini_preme_tubusos.jpg"],
		},
		tre_pini_preme_rapid: {
			title: "Tre Pin Preme Rapid",
			desc:
				"Kiszerelés 1L. Közepes viszkozitású kontakt polikloroprén ragasztó. Nem tartalmaz toluolt és klórozott oldószereket. Különösen alkalmas bőr, parafa, kötél, fém, szövet, fa, poliuretán hab, stb. ragasztására. Szigetelő anyagokhoz is használják. Hő és vízálló. Kiváló kenhetőség; általában ecsettel vagy hengerrel alkalmazzák. Felhasználási szektorok: Hő és hangszigetelés, cipőipar és cipőjavítás, bőrdíszmű gyártás, kárpitos, autókárpitozás, faipar, hajógyártás, építőipar.",
			img: ["tre_pin_rapid.jpg"],
		},
		tre_pini_preme_transparente: {
			title: "Tre Pin Preme Transparente",
			desc:
				"Kiszerelés 1L. Átlátszó közepes viszkozitású kontakt polikloroprén ragasztó. Oldószer alapú ragasztó; Nem tartalmaz toluolt és klórozott oldószereket. Alkalmas bőr, gumi, kerámia, parafa, szövet, fa, fém, műanyag, papír, stb. Különösen a bőriparban, táskák és övek gyártásában, az ortopéd iparban használják. A ragasztott anyagok kiváló rugalmasságot biztosítanak. Kiváló kenhetőség; általában ecsettel vagy hengerrel alkalmazzák. Felhasználási szektorok: Cipőipar és cipőjavítás, bőrdíszmű gyártás, kárpitos, faipar",
			img: ["tre_pin_transparente.jpg"],
		},
		tre_pini_preme_transparente_tubusos: {
			title: "Tre Pin Preme Transparente 75ml tubusos",
			desc:
				"Kiszerelés 1L. Átlátszó közepes viszkozitású kontakt polikloroprén ragasztó. Oldószer alapú ragasztó; Nem tartalmaz toluolt és klórozott oldószereket. Alkalmas bőr, gumi, kerámia, parafa, szövet, fa, fém, műanyag, papír, stb. A ragasztott anyagok kiváló rugalmasságot biztosítanak.",
			img: ["tre_pini_transparente_tubusos.jpg"],
		},
		tre_pin_super_extra_rapid: {
			title: "Tre Pin Super Extra Rapid",
			desc:
				"Kiszerelés: 0,5; 1; és 5 liter. Közepes viszkozitású kontakt polikloroprén ragasztó. Oldószer alapú ragasztó; Nem tartalmaz toluolt és klórozott oldószereket. Hőálló 90C. Nagyon jó gumi, bőr, , parafa, szövet, fa, fém stb. ragasztásához. Leginkább a cipőiparban és a cipőjavításban használják. Ideális autókárpitozáshoz is. Kiváló kenhetőség; általában ecsettel vagy hengerrel alkalmazzák. Felhasználási szektorok: Hő és hangszigetelés, cipőipar és cipőjavítás, autókárpitozás",
			img: ["tre_pini_extra_rapid.jpg"],
		},
		tre_pini_ultrachiaro: {
			title: "Tre Pini 8010 Ultrachiaro",
			desc:
				"Kiszerelés:1; és 5 liter. Közepes viszkozitású színtelen ragasztó. Kiválóan alkalmas cipőgyártás és cipőjavításra. Oldószer alapú ragasztó; Nem tartalmaz toluolt és klórozott oldószereket. Különösen alkalmas gumihoz, TR, SBR, ABS, EVA, parafa, szintetikus anyagokhoz, mikroporózus anyagokhoz, nehéz hordozókhoz, bőrhöz. Kiváló minden nehéz gumihoz. erős tapadás, hosszú nyitott idő. Kiváló kenhetőség; általában ecsettel vagy hengerrel alkalmazzák. Felhasználási szektorok: Cipőipar és cipőjavítás.",
			img: ["tre_pin_ultra.jpg"],
		},

		tre_pini_siteticol: {
			title: "Tre Pini Sinteticol",
			desc:
				"Kiszerelés:1; és 5 liter. Egykomponensű poliuretán ragasztó. Oldószer alapú ragasztó; Nem tartalmaz toluolt és klórozott oldószereket. Kiváló szintetikus anyagokhoz, PVC-hez, kaucsukhoz, TR-hez, poliuretán anyagokhoz, tunit, bőrhöz. 65C termikus reaktiválás szükséges.Kiváló kenhetőség; általában ecsettel vagy hengerrel alkalmazzák. Leginkább a cipőiparban alkalmazzák.",
			img: ["tre_pin_sinteticol.jpg"],
		},
		tre_pini_cemen_super_extra: {
			title: "Cemen Super Extra",
			desc:
				"Kiszerelés:1; és 5 liter. Természetes gumicement a cipő és bőripar számára. Oldószer alapú ragasztó; Nem tartalmaz toluolt és klórozott oldószereket",
			img: ["tre_pin_cemen.jpg"],
		},
		tre_pini_spruzzo: {
			title: "Tre Pin Preme Spruzzo",
			desc:
				"Kiszerelés: 5 liter. Kis viszkozitású kontakt polikloroprén szórható ragasztó. Oldószer alapú színtelen ragasztó; Nem tartalmaz toluolt és klórozott oldószereket. Kiválóan ragaszt habgumi, bőr, szövet és hasonló termékek, fém, fa, cement, műanyag, porózus műanyagok, poliuretán habok. Hő és vízálló. Általában szórópisztollyal alkalmazzák. Felhasználási szektorok: Hő és hangszigetelés, kárpitos, autókárpitozás, faipar, hajógyártás.",
			img: ["tre_pin_spruzzo.jpg"],
		},

		tre_pini_diulente: {
			title: "Diulente",
			desc:
				"Kiszerelés:1; és 5 liter. Hígító polikloroprén ragasztókhoz. Ecsetek, hengerek és szórópisztolyok tisztítására is használható.",
			img: ["tre_pin_diulente.jpg"],
		},
	},
	egyeb_termekek: {
		acellagyek: { title: "Acéllágyék", desc: "", img: ["acellagyek.jpg"] },
		csontkes: { title: "Csontkés", desc: "", img: ["csontkes.jpg"] },
		fenoko: { title: "Fenőkő", desc: "", img: ["fenoko.jpg"] },
		potgleng: { title: "Pótgleng", desc: "", img: ["potgleng.jpg"] },
		ruhajelzo_tinta: {
			title: "Ruhajelző tinta",
			desc: "",
			img: ["ruhajelzo_tinta.jpg"],
		},
		viasz: { title: "Viasz", desc: "", img: ["viasz.jpg"] },
	},
	szegek: {
		belsosarkalo_szeg: {
			title: "Belsősarkaló szeg",
			desc: "",
			img: ["belsosarkalo_szeg.jpg"],
		},
		bognarszeg: { title: "Bognárszeg", desc: "", img: ["bognarszeg.jpg"] },
		faszeg: { title: "Faszeg", desc: "", img: ["faszeg.jpg"] },
		karpítos_disszeg: {
			title: "Kárpitos dísszeg",
			desc: "",
			img: ["karpítos_disszeg.jpg"],
		},
		kezitex: { title: "Kézitex", desc: "", img: ["kezitex.jpg"] },
		kezitex_vekony: {
			title: "Kézitex vékony",
			desc: "",
			img: ["kezitex_vekony.jpg"],
		},
	},
	philips_termekek: {
		philips_jarofolt: {
			title: " Philips PU csapos járófolt",
			desc:
				" Csap átmérője: 2.95 mm Méretek mm: fekete  9.5  10.5  12  13.5  16  17.5  19  22  13.5 un Bézs és fehér: 9.5  13.5  19",
			img: ["odell_jarofolt.jpg"],
		},

		super_dark_jarofolt: {
			title: " Philips Super Dark csapos járófolt",
			desc:
				" Csap átmérője: 2.45 mm Méretek mm: fekete  9.5  10.5  12  13.5  16  19  Bézs és fehér: 9.5  10.5  12 13,5",
			img: ["super_dark_jarofolt.jpg"],
		},

		philips_super_javitocsik: {
			title: " Philips Super Gold javítócsík",
			desc: " Méretek mm:450x20, 30, 40, 50, 90",
			img: ["supergold_csik.jpg"],
		},

		philips_fem_jarofolt: {
			title: " Philips fém csapos járófolt",
			desc: " Csap átmérője: 2.95 mm",
			img: ["metal_tops.jpg"],
		},
	},
}

export default ProdObject
