import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
function LinkTab(props) {
	return (
		<Tab
			component="a"
			onClick={(event) => {
				event.preventDefault()
			}}
			{...props}
		/>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		marginBottom: 50,
	},

	tabs: {
		backgroundColor: "#495054",
		color: "#e3e8ea",
	},
}))

export default function NavBar(props) {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Tabs
					value={props.value}
					className={classes.tabs}
					variant="fullWidth"
					aria-label="nav tabs example">
					<LinkTab
						label="Fertom-Kellék Kft"
						onClick={() => props.onclick(0)}
					/>
					<LinkTab
						onClick={() => {
							props.onclick(1)
							props.setShowCategories(true)
						}}
						label="Termékek"
					/>
					<LinkTab
						onClick={() => props.onclick(2)}
						label="Elérhetőség"
					/>
				</Tabs>
			</AppBar>
		</div>
	)
}
