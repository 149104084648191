import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import CategoryCard from "./CategoryCard"
import Categories from "./Categories"
import SubCategory from "./SubCategory"
import Products from "./Products"

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: "60%",
		margin: "auto",
	},
	grid: {
		height: 140,
		width: 100,
	},
	paper: {
		display: "flex",
		flexWrap: "wrap",
	},
	item: {
		width: "30%",
	},
}))

const categories = Object.keys(Categories)

export default function ProductCategory({ showCategories, setShowCategories }) {
	const [productCategory, setProductCategory] = useState(null)
	const [translation, setTranslation] = useState("")
	const [isSubCategory, setIsSubCategory] = useState(false)

	const spacing = 2
	const classes = useStyles()

	const chooseCateg = (category) => {
		setProductCategory(category)
		setShowCategories(false)
		setTranslation(Categories[category].translate)
		setIsSubCategory(Categories[category].subcategory)
	}

	const back = () => {
		setProductCategory(null)
		setIsSubCategory(false)
		setShowCategories(true)
	}

	return (
		<>
			{productCategory &&
				!showCategories &&
				(isSubCategory ? (
					<SubCategory
						subcategory={productCategory}
						translation={translation}
						back={back}
						product={productCategory}
						category={productCategory}
					/>
				) : (
					<Products
						product={productCategory}
						translation={translation}
						back={back}
						category={null}
					/>
				))}
			{showCategories && (
				<>
					<Grid container className={classes.root} spacing={2}>
						<Grid justify="center" container spacing={spacing}>
							{categories.map((category, index) => (
								<Grid
									key={index}
									item
									onClick={() => chooseCateg(category)}>
									<CategoryCard
										title={Categories[category].translate}
										product={category}
										image={Categories[category].img}
										category={categories[index]}
									/>
								</Grid>
							))}
						</Grid>
					</Grid>
				</>
			)}
		</>
	)
}
