import "./App.sass"
import React, { useState } from "react"
import ProductCategory from "./components/ProductCategory"
import NavBar from "./components/NavBar"
import Homepage from "./components/Homepage"
import Map from "./components/Map"

function App() {
	const [page, setPage] = useState(0)
	const [showCategories, setShowCategories] = useState(true)

	const Pages = [
		{
			name: "main",
			content: <Homepage />,
		},
		{
			name: "products",
			content: (
				<ProductCategory
					showCategories={showCategories}
					setShowCategories={setShowCategories}
				/>
			),
		},
		{
			name: "contact",
			content: <Map />,
		},
	]

	return (
		<>
			<NavBar
				onclick={setPage}
				value={page}
				setShowCategories={setShowCategories}
			/>
			{Pages[page].content}
		</>
	)
}

export default App
