import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "13rem",
		height: "14rem",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "center",
	},
	cardheader: {
		fontSize: "0.5rem",
	},
	media: {
		paddingTop: "56.25%",
		backgroundSize: "contain",
		width: "100%",
	},
}))

export default function CategoryCard({ product, image, title, category }) {
	const classes = useStyles()
	const imgUrl =
		category === product
			? process.env.PUBLIC_URL + "/img/" + product + "/" + image
			: process.env.PUBLIC_URL +
			  "/img/" +
			  category +
			  "/" +
			  product +
			  "/" +
			  image
	return (
		<>
			<Card className={classes.root}>
				<CardMedia className={classes.media} image={imgUrl} />
				<CardHeader className={classes.header} title={title} />
			</Card>
		</>
	)
}
