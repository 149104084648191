import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import CategoryCard from "./CategoryCard"
import Categories from "./Categories"
import Products from "./Products"
import Link from "@material-ui/core/Link"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: "60%",
		margin: "auto",
	},
	grid: {
		height: 140,
		width: 100,
	},
	paper: {
		display: "flex",
		flexWrap: "wrap",
	},
	item: {
		width: "30%",
	},
}))

export default function SubCategory(props) {
	const [subCategory, setSubCategory] = useState(null)
	const [showCategories, setshowCategories] = useState(true)
	const [translate, setTranslate] = useState("")
	const spacing = 2
	const classes = useStyles()
	const subcategories = Categories[props.subcategory].subCategories

	const chooseCateg = (sub, translate) => {
		setSubCategory(sub)
		setshowCategories(false)
		setTranslate(translate)
	}

	const back = () => {
		setSubCategory(null)
		setshowCategories(true)
	}

	return (
		<>
			{subCategory && !showCategories && (
				<Products
					product={subCategory}
					translation={translate}
					subback={back}
					subcategory={props.translation}
					back={props.back}
					category={props.category}
				/>
			)}
			{showCategories && subCategory === null && (
				<Grid container className={classes.root} spacing={2}>
					<Breadcrumbs
						separator="›"
						aria-label="breadcrumb"
						className={classes.breadcrumbs}>
						<Link
							color="inherit"
							href="#"
							onClick={() => props.back()}>
							Termékek
						</Link>
						<Link color="inherit" href={"#"}>
							{props.translation}
						</Link>
					</Breadcrumbs>
					<Grid justify="center" container spacing={spacing}>
						{subcategories.map((subcategory, index) => (
							<Grid
								key={subcategory.category}
								item
								onClick={() =>
									chooseCateg(
										subcategory.category,
										subcategory.translate
									)
								}>
								<CategoryCard
									title={subcategory.translate}
									product={subcategory.category}
									image={subcategory.img}
									category={props.product}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
			)}
		</>
	)
}
