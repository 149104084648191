const CategObject = {
	ragasztok: {
		img: "ragasztok.jpg",
		translate: "Ragasztók",
		subcategory: false,
	},
	szegek: {
		img: "kezitex_vekony.jpg",
		translate: "Szegek",
		subcategory: false,
	},
	cipojavito_anyagok: {
		subCategories: [
			{
				category: "michelin_termekek",
				img: "michelin.jpg",
				translate: "Michelin termékek",
			},
			{
				category: "philips_termekek",
				img: "daviesodell.jpg",
				translate: "Philips termékek",
			},
			{
				category: "dunlop_termekek",
				img: "dunlop.jpg",
				translate: "Dunlop termékek",
			},
			{
				category: "ghl_termekek",
				img: "ghl.png",
				translate: "GHL termékek",
			},
		],
		subcategory: true,
		img: "cipojavito_anyagok.jpg",
		translate: "Cipőjavító anyagok",
	},
	gumilemezek: {
		img: "gumilemezek.jpg",
		translate: "Gumilemezek",
		subcategory: false,
	},
	egyeb_termekek: {
		img: "acellagyek.jpg",
		translate: "Egyéb termékek",
		subcategory: false,
	},
}

export default CategObject
