import React from "react"

const containerStyle = {
	height: "80vh",
	width: "100vw",
	fontFamily: "Roboto",
}
function MyComponent() {
	return (
		<>
			<div className="map-div">
				<h3>Fertom Kellék Kft.</h3>
				<p> Kámfor u. 37 , Budapest, 1134</p>

				<a href="mailto:fertomkft@gmail.com">fertomkft@gmail.com</a>
				<br />
				<a href="tel:+36209982350">+36209982350</a>
				<ul>
					<li> hétfő 9:00–15:00</li>
					<li> kedd 9:00–15:00</li>
					<li> szerda 9:00–15:00</li>
					<li> csütörtök 9:00–15:00</li>
					<li> péntek 9:00–12:30</li>
				</ul>
				<p>
					Nagykereskedésünkben csak vállalkozókat tudunk kiszolgálni.
					Csomagküldést is vállalunk.
				</p>
			</div>
			<div className="mapouter">
				<div className="gmap_canvas">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2693.1315892974817!2d19.0924579!3d47.5457676!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dbb1b2326381%3A0x1e69192568a3f24!2sFertom-Kell%C3%A9k%20kft!5e0!3m2!1shu!2shu!4v1614432178903!5m2!1shu!2shu"
						width="80%"
						height="80%"
						style={containerStyle}
						allowFullScreen=""
						loading="lazy"
						title="map"></iframe>
				</div>
			</div>
		</>
	)
}

export default React.memo(MyComponent)
