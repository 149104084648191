import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Modal from "@material-ui/core/Modal"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "14rem",
		minHeight: "20rem",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "center",
	},
	item: {
		height: "16rem",
	},
	title: {
		fontSize: "0.5rem",
	},
	media: {
		paddingTop: "56.25%", // 16:9
		backgroundSize: "contain",
		width: "100%",
	},
	card: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "center",
	},

	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	cardactions: {
		width: "100%",
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	modalImg: {
		maxWidth: "40%",
		maxHeight: "50%",
		["@media (max-width:780px)"]: { maxWidth: "70%" },
	},
}))

export default function ProdCard(props) {
	const classes = useStyles()
	const [expanded, setExpanded] = React.useState(false)
	const [open, setOpen] = React.useState(false)
	const [image, setImage] = React.useState()
	const handleOpen = (image) => {
		setOpen(true)
		setImage(image)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleExpandClick = () => {
		setExpanded(!expanded)
	}

	const modalBody = (
		<img
			onClick={() => handleClose()}
			className={classes.modalImg}
			src={image}
			alt={image}
		/>
	)

	const imgUrl = props.category
		? process.env.PUBLIC_URL +
		  "/img/" +
		  props.category +
		  "/" +
		  props.productCategory +
		  "/"
		: process.env.PUBLIC_URL + `/img/` + props.productCategory + "/"

	const picNum = 90 / props.images.length

	return (
		<Card className={classes.root}>
			<CardHeader className={classes.cardheader} title={props.title} />
			{props.images.map((file, i) => (
				<CardMedia
					key={i}
					style={{ width: picNum + "%" }}
					className={classes.media}
					image={imgUrl + file}
					onClick={() => handleOpen(imgUrl + file)}
				/>
			))}
			<CardActions disableSpacing className={classes.cardactions}>
				<IconButton
					className={clsx(classes.expand, {
						[classes.expandOpen]: expanded,
					})}
					onClick={handleExpandClick}
					aria-expanded={expanded}
					aria-label="show more">
					<svg
						width="1rem"
						aria-hidden="true"
						focusable="false"
						data-prefix="fas"
						data-icon="chevron-down"
						className="svg-inline--fa fa-chevron-down fa-w-14"
						role="img"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 448 512">
						<path
							fill="silver"
							d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
					</svg>
				</IconButton>
			</CardActions>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent>
					<Typography paragraph>{props.shortDescription}</Typography>
				</CardContent>
			</Collapse>
			<Modal
				disableEnforceFocus
				disableAutoFocus
				className={classes.modal}
				open={open}
				onClose={handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description">
				{modalBody}
			</Modal>
		</Card>
	)
}
